<template>
  <el-table-column
    :label="columnConfig.label"
    :align="columnConfig.align"
  >
    <template slot="header">
      <div class="medium-fact-column">
        <div>{{ columnConfig.label }}</div>
      </div>
    </template>


    <el-table-column
      v-for="(card, cardId) in cards"
      :key="cardId"
      :width="200"
      align="center"
      :label="cardId"
    >
      <template slot="header" slot-scope="scope">
        {{ card.user.full_name }}
      </template>
      <template v-slot="scope">
        <el-checkbox
          :value="scope.row.weight[card.id]===100"
          @change="checkChanged($event, scope.row, card.id)"
        ></el-checkbox>
      </template>
    </el-table-column>
  </el-table-column>
</template>

<script>

import ClickToEdit from "@/components/ClickToEdit";
import {ElementPropTableColumn} from "@/mixins/elementPropTableColumn";

export default {
  name: 'matrix-multiplier-weight-table-column',
  components: {ClickToEdit},
  mixins: [ElementPropTableColumn],

  props: {
    isMatrix: {type: Boolean, default: true},
    columnConfig: {type: Object, required: true},
    cards: {},
  },


  data() {
    return {}
  },
  methods: {
    checkChanged(value, row, cardId){
      row.weight[cardId] = value ? 100 : null;
      this.saveElementPropChange(row, 'weight');
    }
  }
}

</script>

<style>

</style>
